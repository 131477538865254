<template>
  <div class="bg bg6">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
      <div class="class--con__title1" v-if="courseMasterWorkList.courseNames">
        <div style="font-size: 45px">2025年核心课程说明</div>
<!--        <span style="font-size: 24px;" v-for="(i,k) in courseMasterWorkList.courseNames.slice(0,5)"-->
<!--              :key="k">{{ i }}、</span>-->
        <span style="font-size: 24px;" v-if="position === 0">{{courseMasterWorkList.courseNameOne}}</span>
        <span style="font-size: 24px;" v-else>{{courseMasterWorkList.tip4s[position - 1].var1}}</span>
      </div>
      <div class="class--container">
        <div style="display: flex">
          <img class="class--con__titleImg" :src="courseMasterWorkList.imgUrl" alt="" >
          <div class="class--con__top">
            <!--            <span class="iocn"></span>-->
            <!--            <span class="class&#45;&#45;con&#45;&#45;top__title">发展特点：</span>-->
            <span class="class--con--top__content" v-html="formattedTip2" v-if="position === 0"></span>
            <span class="class--con--top__content" v-html="formattedTip4s[position - 1].var2"  v-else></span>

          </div>
<!--          <div class="class&#45;&#45;con__top">-->
<!--            &lt;!&ndash;            <span class="iocn"></span>&ndash;&gt;-->
<!--            &lt;!&ndash;            <span class="class&#45;&#45;con&#45;&#45;top__title">发展特点：</span>&ndash;&gt;-->
<!--            <span class="class&#45;&#45;con&#45;&#45;top__content">{{ courseMasterWorkList.tip3 }}</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "sixteenPage",
  components: {ClassBut},
  data() {
    return {
      position:0,
      courseMasterWorkList: {}
    }
  },
  computed: {
    // 格式化 tip2
    formattedTip2() {
      return this.formatHtml(this.courseMasterWorkList.tip2);
    },
    // 格式化 tip4s
    formattedTip4s() {
      return this.courseMasterWorkList.tip4s.map(tip => ({
        var1: tip.var1,
        var2: this.formatHtml(tip.var2)
      }));
    }
  },
  created() {
    this.gitApicourseMasterWork()
  },
  methods: {
    //核心课程说明
    forward() {
      if ( this.courseMasterWorkList.tip4s.length > 0 ) {
        if ( this.position === this.courseMasterWorkList.tip4s.length ) {
          this.$router.push({
            name: 'PageSeventeenth',
            query: this.$route.query,
          })
        }
        this.position++;
      }else {
        this.$router.push({
          name: 'PageSeventeenth',
          query: this.$route.query,
        })
      }
    },
    backOfff(){
      if ( this.position > 0 ) {
        this.position -= 1;
      }else {
        this.$router.back(-1)
      }
    },
    async gitApicourseMasterWork() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseMsgInfo`, {
        classId: this.$route.query.classId
      })
      this.courseMasterWorkList = res

    },
    formatHtml(html) {
      const container = document.createElement("div");
      container.innerHTML = html;

      // 替换 <p> 为 <div>
      container.querySelectorAll("p").forEach(p => {
        const div = document.createElement("div");
        div.innerHTML = p.innerHTML; // 保留原内容
        p.replaceWith(div); // 替换
      });

      // 移除 <br>
      container.querySelectorAll("br").forEach(br => br.remove());

      return container.innerHTML;
    }
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 0px 90px;
  box-sizing: border-box;
  color: #2B3654;
  font-size: 24px;

  .class--con__titleImg {
    width: 706px;
    height: 529px;
    border-radius: 10px;
    border: 5px solid #FFC94D;
    object-fit: contain;
  }

  .class--con__bottom {
    width: 100%;
    height: 60px;
    /*line-height: 60px;*/
    /*overflow:hidden;*/
    /*white-space:nowrap;*/
    /*text-overflow:ellipsis;*/

    .class--con__bottom__title {
      color: #2B3654;
      font-size: 24px;
      font-weight: bold;
    }

    .class--con__bottom__content {
      color: #666666;
      font-size: 22px;
    }
  }

  .iocn {
    width: 16px;
    height: 16px;
    background: #5BCD98;
    border-radius: 50%;
    display: inline-block;
  }

  .class--con__top {
    overflow-y: auto;
    width: 100%;
    height: 525px;
    padding: 10px;
    box-sizing: border-box;

    .class--con--top__title {
      font-size: 24px;
      color: #2B3654;
      font-weight: Bold;
    }

    .class--con--top__content {
      color: #666666;
      font-size: 24px;
      line-height: 40px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.class--con__border {
  height: 180px;
  overflow-y: auto;
}

.class--con__title1 {
  color: #2B3654;
  font-weight: bold;
  margin-left: 90px;
  margin-top: 45px;
  margin-bottom: 20px;
}
</style>
